<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-overlay :show="loading">
            <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                <b-row>
                    <!-- garden name -->
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Garden Name" vid="garden_id" rules="required|min_value:1">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="garden_id">
                            <template v-slot:label>
                            {{ $t('teaGardenConfig.select_garden') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                :disabled="isGardenAdmin"
                                plain
                                v-model="workerInformation.garden_id"
                                :options="teaGardenGenInfoList"
                                id="garden_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="PF ID" vid="pf_id" rules="required">
                            <b-form-group slot-scope="{ valid, errors }" label-for="pf_id">
                                <template v-slot:label>
                                    {{ $t('teaGardenConfig.pf_id') }}  <span class="text-danger">*</span>
                                </template>
                                <b-form-input id="pf_id" v-model="workerInformation.pf_id"
                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Worker Name (En)" vid="worker_name_en" rules="required">
                            <b-form-group  slot-scope="{ valid, errors }" label-for="worker_name_en">
                                <template v-slot:label>
                                    {{ $t('teaGardenConfig.worker_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input id="worker_name" v-model="workerInformation.worker_name_en"
                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Worker Name(Bn)" vid="worker_name_bn" rules="required">
                            <b-form-group slot-scope="{ valid, errors }" label-for="worker_name_bn">
                                <template v-slot:label>
                                    {{ $t('teaGardenConfig.worker_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input id="worker_name_bn" v-model="workerInformation.worker_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Employee Type" vid="employee_type" rules="required|min_value:1">
                            <b-form-group slot-scope="{ valid, errors }" label-for="employee_type">
                                <template v-slot:label>
                                    {{ $t('teaGarden.employee_type') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select plain v-model="workerInformation.employee_type" :options="employeeType" id="designation_id"
                                    :state="errors[0] ? false : (valid ? true : null)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="designation" vid="designation_id" rules="required|min_value:1">
                            <b-form-group slot-scope="{ valid, errors }" label-for="designation_id">
                                <template v-slot:label>
                                    {{ $t('teaGardenConfig.designation') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select plain v-model="workerInformation.designation_id" :options="designationList" id="designation_id"
                                    :state="errors[0] ? false : (valid ? true : null)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Father Name(En)" vid="father_name_en" rules="">
                            <b-form-group slot-scope="{ valid, errors }" label-for="father_name_en">
                                <template v-slot:label>
                                    {{ $t('teaGardenConfig.father_name') }} {{ $t('globalTrans.en') }}
                                </template>
                                <b-form-input id="father_name_en" v-model="workerInformation.father_name_en"
                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Father Name(Bn)" vid="father_name_bn" rules="">
                            <b-form-group slot-scope="{ valid, errors }" label-for="father_name_bn">
                                <template v-slot:label>
                                    {{ $t('teaGardenConfig.father_name') }} {{ $t('globalTrans.bn') }}
                                </template>
                                <b-form-input id="father_name_bn" v-model="workerInformation.father_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Mother Name(En)" vid="mother_name_en" rules="">
                            <b-form-group slot-scope="{ valid, errors }" label-for="mother_name_en">
                                <template v-slot:label>
                                    {{ $t('globalTrans.mother_name') }} {{ $t('globalTrans.en') }}
                                </template>
                                <b-form-input id="mother_name_en" v-model="workerInformation.mother_name_en"
                                              :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Mother Name(Bn)" vid="mother_name_bn" rules="">
                            <b-form-group slot-scope="{ valid, errors }" label-for="mother_name_bn">
                                <template v-slot:label>
                                    {{ $t('globalTrans.mother_name') }} {{ $t('globalTrans.bn') }}
                                </template>
                                <b-form-input id="mother_name_bn" v-model="workerInformation.mother_name_bn"
                                              :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Worker Type" vid="worker_type" rules="">
                            <b-form-group slot-scope="{ valid, errors }" label-for="worker_type">
                                <template v-slot:label>
                                    {{ $t('teaGardenConfig.worker_type') }}
                                </template>
                                <b-form-select plain v-model="workerInformation.worker_type" :options="customWorkerTypeList" id="worker_type"
                                    :state="errors[0] ? false : (valid ? true : null)">
                                <template v-slot:first>
                                   <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Gender" vid="gender" rules="required|min_value:1">
                            <b-form-group slot-scope="{ valid, errors }" label-for="gender">
                                <template v-slot:label>
                                    {{ $t('teaGardenConfig.gender') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select plain v-model="workerInformation.gender" :options="genderList" id="gender"
                                    :state="errors[0] ? false : (valid ? true : null)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Address (En)" vid="address_en">
                            <b-form-group label-for="address_en">
                                <template v-slot:label>
                                    {{ $t('teaGardenConfig.address') }} {{ $t('globalTrans.en') }}
                                </template>
                                <b-form-textarea id="address_en" v-model="workerInformation.address_en"
                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Address (Bn)" vid="address_bn">
                            <b-form-group label-for="address_bn">
                                <template v-slot:label>
                                    {{ $t('teaGardenConfig.address') }} {{ $t('globalTrans.bn') }}
                                </template>
                                <b-form-textarea id="address_bn" v-model="workerInformation.address_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="NID" vid="nid" rules="">
                            <b-form-group slot-scope="{ valid, errors }" label-for="nid">
                                <template v-slot:label>
                                    {{ $t('teaGardenConfig.nid') }}
                                </template>
                                <b-form-input type="number" min="0" id="nid" v-model="workerInformation.nid"
                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Mobile No" vid="mobile" rules="required|min:11|max:11">
                        <b-form-group slot-scope="{ valid, errors }" label-for="mobile">
                            <template v-slot:label>
                                {{ $t('teaGardenConfig.mobile') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input type="tel" id="mobile" v-model="workerInformation.mobile" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                :state="errors[0] ? false : (valid ? true : null)" ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Date of Birth" vid="date_of_birth" rules="">
                        <b-form-group slot-scope="{ valid, errors }" label-for="date_of_birth">
                            <template v-slot:label>
                                {{ $t('globalTrans.dob') }}
                            </template>
                            <date-picker
                                id="date_of_birth"
                                v-model="workerInformation.date_of_birth"
                                class="form-control"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :class="errors[0] ? 'is-invalid' : ''"
                                :config="config"
                                :locale="currentLocale"
                                >
                            </date-picker>
                            <div class="invalid-feedback" :class="errors.length ? 'd-block' : ''">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Sorting Order" vid="sorting_order" :rules="{required: true}">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="sorting_order">
                            <template v-slot:label>
                            {{ $t('teaGarden.sorting_order') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="sorting_order"
                            v-model="workerInformation.sorting_order"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <!-- salary information -->
                    <b-col sm="12">
                        <div class="group-form-card">
                            <b-card>
                                <b-form-group label-cols-lg="12" :label="$t('teaGardenConfig.salary_info')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                    <b-col sm="12" v-if="workerInformation.employee_type">
                                        <ValidationProvider name="Salary Info" vid="selected_salary_data" :rules="{required: false}">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            label-for="amount">
                                            <template v-slot:label>
                                                {{ $t('teaGarden.select_head') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-checkbox-group
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                class="custom-control-inline-wrapper" size="lg" v-model="workerInformation.selected_salary_data" :options="wagesHeadList" name="">
                                            </b-form-checkbox-group>
                                            <div class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="12">
                                            <table class="table table-sm table-bordered section-tree-view-table">
                                                <thead>
                                                    <tr>
                                                        <th>{{ $t('teaGarden.head_name') }}</th>
                                                        <th>{{ $t('teaGarden.add_deduct') }}</th>
                                                        <th>{{ $t('teaGarden.amount') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <slot v-for="(item, index) in workerInformation.salary_info">
                                                        <tr :key="index">
                                                        <td>{{ currentLocale === 'en' ? item.text_en : item.text_bn }}</td>
                                                        <td>{{ getHeadAccountTypeName(item.add_deduct) }}</td>
                                                        <td>
                                                            <ValidationProvider name="Amount" :vid="'set_amount' + '_' + index" rules="required|min_value: 0">
                                                                <b-form-group
                                                                class="mb-0"
                                                                slot-scope="{ valid, errors }">
                                                                <b-form-input
                                                                     @change="grossSalary"
                                                                    :id="`set_amount-${index}`"
                                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                    class="text-right"
                                                                    min="1"
                                                                    v-model="item.set_amount"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                                <div class="error invalid-feedback" role="alert">
                                                                    {{ errors[0] }}
                                                                </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </td>
                                                        </tr>
                                                    </slot>
                                                    <tr>
                                                        <td class="text-right" colspan="2"><b>{{$t('teaGarden.gross_salary')}}</b></td>
                                                        <td class="text-right"><b>{{workerInformation.gross_salary}}</b></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot v-if="Object.keys(workerInformation.salary_info).length === 0">
                                                    <tr>
                                                        <td colspan="3" class="text-center text-danger">{{ $t('teaGardenPanel.no_data_found') }}</td>
                                                        </tr>
                                                </tfoot>
                                            </table>
                                    </b-col>
                                </b-form-group>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="text-right">
                    <b-col>
                        <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                        <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{
                        $t('globalTrans.cancel') }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-overlay>
    </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { workerinformationStoreApi, workerinformationUpdateApi, getPfIdApi, providentFundInfo } from '../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
    mixins: [teaGardenService],
    name: 'Form',
    props: ['id'],
    data () {
        return {
            valid: null,
            config: { static: true },
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            errors: [],
            workerInformation: {
                id: '',
                designation_id: 0,
                garden_id: 0,
                employee_type: 0,
                pf_id: '',
                worker_name_en: '',
                worker_name_bn: '',
                father_name_en: '',
                father_name_bn: '',
                mother_name_en: '',
                mother_name_bn: '',
                nid: '',
                mobile: '',
                gender: 0,
                worker_type: 0,
                date_of_birth: '',
                address_en: '',
                address_bn: '',
                selected_salary_data: [],
                salary_info: [],
                gross_salary: 0
            },
            isGardenAdmin: false,
            designationList: [],
            wagesHeadList: [],
            provident_fund_info: {}
        }
    },
    created () {
        if (this.id) {
            const tmp = this.getworkerInformation()
            this.workerInformation = tmp
            // for salary amount_set data get
            const tmpData = this.getworkerInformation()
            setTimeout(() => {
               this.wagesHeadList.map(item => {
                const headAmountGet = tmpData.salary_info.find(salaryItem => salaryItem.value === item.value)
                if (headAmountGet) {
                    return Object.assign(item, { set_amount: headAmountGet.set_amount })
                } else {
                    return item
                }
            })
            this.setSalaryData()
            }, 2000)
        } else {
            this.getPfId()
        }
        this.getProfidentFundInfo()
        if (this.isGardenAdminCheckGardenId()) {
            this.isGardenAdmin = true
            this.workerInformation.garden_id = this.isGardenAdminCheckGardenId()
        }
    },
    computed: {
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        customWorkerTypeList: function () {
            return this.$store.state.TeaGardenService.commonObj.workerType
        },
        genderList: function () {
            return this.$store.state.TeaGardenService.commonObj.gender
        },
        teaGardenGenInfoList: function () {
            return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
        },
        currentLocale () {
            return this.$i18n.locale
        },
        employeeType () {
            return this.$store.state.TeaGardenService.commonObj.employeeType
        }
    },
    methods: {
        async saveUpdate () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            if (this.id) {
                result = await RestApi.putData(teaGardenServiceBaseUrl, `${workerinformationUpdateApi}/${this.id}`, this.workerInformation)
            } else {
                result = await RestApi.postData(teaGardenServiceBaseUrl, workerinformationStoreApi, this.workerInformation)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-form')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        async getPfId () {
            let result = null
            result = await RestApi.getData(teaGardenServiceBaseUrl, getPfIdApi)
            if (result) {
                this.workerInformation.pf_id = result
            }
        },
        getworkerInformation () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        getDesignationList: function (id) {
            this.designationList = []
            this.designationList = this.$store.state.TeaGardenService.commonObj.masterDesignationList.filter(item => item.employee_type === id)
        },
        getWagesHeadList: function (id) {
            this.wagesHeadList = []
            this.wagesHeadList = this.$store.state.TeaGardenService.commonObj.gdnWagesHeadList.filter(item => JSON.parse(item.employee_types).includes(id))
        },
        getHeadAccountTypeName (id) {
            const obj = this.$store.state.TeaGardenService.commonObj.headAccountType.find(item => item.value === id)
            if (obj) {
                return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
            }
        },
        setSalaryData () {
            this.workerInformation.salary_info = []
            this.workerInformation.selected_salary_data.forEach(element => {
                const wagesHead = this.wagesHeadList.find(item => item.value === element)
                if (wagesHead) {
                    // only checked when eatch wages_head amount gether than zero
                    if (parseFloat(wagesHead.amount) > 0) {
                        if (!wagesHead.set_amount) {
                            wagesHead.set_amount = wagesHead.amount
                        }
                    }
                    this.workerInformation.salary_info.push(wagesHead)
                }
            })
            this.grossSalary()
        },
        grossSalary () {
            var total = 0
            this.workerInformation.salary_info.forEach(element => {
                if (element.set_amount) {
                    // pf amount set
                     const findBasicSalary = this.wagesHeadList.find(item => item.value === element.value)
                        if (findBasicSalary && findBasicSalary.text_en.toLowerCase() === 'basic salary') {
                           const findPfHead = this.wagesHeadList.find(item => item.text_en.toLowerCase() === 'pf')
                           if (findPfHead) {
                                if (this.workerInformation.employee_type === 1 && this.provident_fund_info && this.provident_fund_info.pf_percentage_employee) {
                                    this.wagesHeadList.map(pfSetItem => {
                                        if (findPfHead.value === pfSetItem.value) {
                                            const setAmount = (parseFloat(this.provident_fund_info.pf_percentage_employee) / 100) * element.set_amount
                                            const pfValueSet = {
                                                amount: setAmount.toFixed(2)
                                            }
                                            return Object.assign(pfSetItem, pfValueSet)
                                        }
                                        return Object.assign(pfSetItem)
                                    })
                                    // when basic salary change call this
                                    this.workerInformation.salary_info.map(pfSetItem => {
                                        if (findPfHead.value === pfSetItem.value) {
                                            const setAmount = (parseFloat(this.provident_fund_info.pf_percentage_employee) / 100) * element.set_amount
                                            const pfValueSet = {
                                                set_amount: setAmount.toFixed(2)
                                            }
                                            return Object.assign(pfSetItem, pfValueSet)
                                        }
                                        return Object.assign(pfSetItem)
                                    })
                                } else if (this.workerInformation.employee_type === 2 && this.provident_fund_info && this.provident_fund_info.pf_percentage_stuff) {
                                    this.wagesHeadList.map(pfSetItem => {
                                        if (findPfHead.value === pfSetItem.value) {
                                            const setAmount = (parseFloat(this.provident_fund_info.pf_percentage_stuff) / 100) * element.set_amount
                                            const pfValueSet = {
                                                amount: setAmount.toFixed(2)
                                            }
                                            return Object.assign(pfSetItem, pfValueSet)
                                        }
                                        return Object.assign(pfSetItem)
                                    })
                                  // when basic salary change call this
                                  this.workerInformation.salary_info.map(pfSetItem => {
                                        if (findPfHead.value === pfSetItem.value) {
                                            const setAmount = (parseFloat(this.provident_fund_info.pf_percentage_stuff) / 100) * element.set_amount
                                            const pfValueSet = {
                                                set_amount: setAmount.toFixed(2)
                                            }
                                            return Object.assign(pfSetItem, pfValueSet)
                                        }
                                        return Object.assign(pfSetItem)
                                    })
                                }
                           }
                        }
                    if (element.add_deduct === 1) {
                        total += parseFloat(element.set_amount)
                    } else {
                        total -= parseFloat(element.set_amount)
                    }
                }
            })
            this.workerInformation.gross_salary = total.toFixed(2)
        },
        async getProfidentFundInfo () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const loadingState = { loading: true, listReload: false }
            let result = null
            result = await RestApi.getData(teaGardenServiceBaseUrl, providentFundInfo)
            if (result.success) {
                this.provident_fund_info = result.data
            }
            loadingState.loading = false
            this.$store.dispatch('mutateCommonProperties', loadingState)
        }
    },
    watch: {
        'workerInformation.employee_type': function (newVal, oldVal) {
            this.getDesignationList(newVal)
            this.getWagesHeadList(newVal)
        },
        'workerInformation.selected_salary_data': function (newVal, oldVal) {
            this.setSalaryData()
        }
    }
}
</script>
