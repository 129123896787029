<template>
    <b-overlay :show="loading">
    <div class="form-wrapper tea-garden-profile-wrapper">
        <b-row>
            <b-col lg="12" sm="12">
                <body-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <template>
                                    <div class="mb-2 d-flex justify-content-end"><b-button variant="primary" @click="pdfExport" size="sm"><i class="ri-printer-fill text-right"></i>{{
                                    $t('globalTrans.export_pdf') }} </b-button></div>
                                </template>
                                <template>
                                    <div
                                        style="font-size:12px; font-color:white; background-color: #dddddd; padding:6px">
                                        <h5 class="card-title text-center"
                                            style="margin-bottom: 0;color: #214162;font-size: 1.40rem;"> {{
                                            $t('teaGardenConfig.Worker_Details') }} {{ $t('globalTrans.details') }}</h5>
                                    </div>
                                </template>
                                <table v-if="detailsData.id" class="table table-borderless">
                                    <tr>
                                        <th style="width: 25%">{{$t('teaGardenConfig.garden_name')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ getTeaGardenName(detailsData.garden_id) }}</td>
                                    </tr>
                                    <tr>
                                        <th style="width: 25%">{{$t('teaGardenConfig.pf_id')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ EngBangNum(detailsData.pf_id) }}</td>
                                    </tr>
                                     <tr>
                                        <th style="width: 25%"> {{$t('teaGardenConfig.worker_name')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ ($i18n.locale === 'bn') ? detailsData.worker_name_bn : detailsData.worker_name_en }}</td>
                                    </tr>
                                    <!-- <tr>
                                        <th style="width: 25%"> {{$t('teaGardenConfig.employee_type')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ getEmployeeType (detailsData.employee_type) }}</td>
                                    </tr> -->
                                    <tr>
                                        <th style="width: 25%"> {{$t('teaGardenConfig.designation')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ ($i18n.locale === 'bn') ? detailsData.designation_bn : detailsData.designation_en
                                        }}</td>
                                    </tr>
                                    <tr>
                                        <th style="width: 25%"> {{$t('teaGardenConfig.father_name')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ ($i18n.locale === 'bn') ? detailsData.father_name_bn : detailsData.father_name_en}}</td>
                                    </tr>
                                    <tr>
                                        <th style="width: 25%"> {{$t('globalTrans.mother_name')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ ($i18n.locale === 'bn') ? detailsData.mother_name_bn : detailsData.mother_name_en}}</td>
                                    </tr>
                                    <tr>
                                        <th style="width: 25%">{{$t('teaGardenConfig.gender')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ getGenderName(detailsData.gender) }}</td>
                                    </tr>
                                    <tr>
                                        <th style="width: 25%">{{$t('teaGardenConfig.nid')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ EngBangNum(detailsData.nid) }}</td>
                                    </tr>
                                    <tr>
                                        <th style="width: 25%">{{$t('teaGardenConfig.mobile')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ EngBangNum(detailsData.mobile) }}</td>
                                    </tr>
                                    <tr>
                                        <th style="width: 25%">{{$t('globalTrans.dob')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ EngBangNum(detailsData.date_of_birth) }}</td>
                                    </tr>
                                    <tr>
                                        <th style="width: 25%"> {{$t('teaGardenConfig.address')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ ($i18n.locale === 'bn') ? detailsData.address_bn : detailsData.address_en}}</td>
                                    </tr>
                                    <tr>
                                        <th style="width: 25%"> {{$t('teaGardenConfig.worker_type')}}</th>
                                        <th style="width: 2%">:</th>
                                        <td style="width: 70%">{{ ($i18n.locale === 'bn') ? detailsData.worker_type_bn : detailsData.worker_type_en}}</td>
                                    </tr>
                                </table>
                            </b-col>
                            <b-col sm="12" v-if="detailsData.salary_info && detailsData.gross_salary > 0">
                                <h5 class="font-weight-bold">{{$t('teaGardenConfig.salary_info')}}</h5>
                                <table class="table table-sm table-bordered section-tree-view-table">
                                    <thead>
                                        <tr>
                                            <th>{{ $t('teaGarden.head_name') }}</th>
                                            <th>{{ $t('teaGarden.add_deduct') }}</th>
                                            <th>{{ $t('teaGarden.amount') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <slot v-for="(item, index) in detailsData.salary_info">
                                            <tr :key="index">
                                            <td>{{ currentLocale === 'en' ? item.text_en : item.text_bn }}</td>
                                            <td>{{ getHeadAccountTypeName(item.add_deduct) }}</td>
                                            <td>
                                                {{ item.set_amount }}
                                            </td>
                                            </tr>
                                        </slot>
                                        <tr>
                                            <td class="text-right" colspan="2"><b>{{$t('teaGarden.gross_salary')}}</b></td>
                                            <td><b>{{detailsData.gross_salary}}</b></td>
                                        </tr>
                                    </tbody>
                                    <tfoot v-if="Object.keys(detailsData.salary_info).length === 0">
                                        <tr>
                                            <td colspan="3" class="text-center text-danger">{{ $t('teaGardenPanel.no_data_found') }}</td>
                                            </tr>
                                    </tfoot>
                                </table>
                            </b-col>
                        </b-row>
                    </template>
                </body-card>
            </b-col>
        </b-row>
    </div>
    </b-overlay>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { employeeDetails } from '../../api/routes'
export default {
    name: 'Details',
    props: ['id'],
    data () {
        return {
            teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
            valid: null,
            errors: [],
            detailsData: {}
        }
    },
    created () {
        if (this.id) {
            const tmp = this.getworkerInformation()
            this.detailsData = tmp
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        authUser: function () {
            return this.$store.state.Auth.authUser
        },
        loading () {
            return this.$store.state.commonObj.loading
        }
    },
    methods: {
        EngBangNum (n) {
            if (this.$i18n.locale === 'bn') {
                return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            } else {
                return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
            }
        },
        getGenderName (id) {
            const Gender = this.$store.state.TeaGardenService.commonObj.gender.find(el => el.value === parseInt(id))
            if (Gender !== undefined) {
                return this.$i18n.locale === 'en' ? Gender.text_en : Gender.text_bn
            }
        },
        getEmployeeType (id) {
            const employeeType = this.$store.state.TeaGardenService.commonObj.employeeType.find(el => el.value === parseInt(id))
            if (employeeType !== undefined) {
                return this.$i18n.locale === 'en' ? employeeType.text_en : employeeType.text_bn
            }
        },
        getDesignation (id) {
            const designation = this.$store.state.TeaGardenService.commonObj.masterDesignationList.find(item => item.value === id)
            if (designation && this.$i18n.locale === 'bn') {
                return designation.text_bn
            } else if (designation && this.$i18n.locale === 'en') {
                return designation.text_en
            }
        },
        getworkerInformation () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async pdfExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
        // if (this.authUser) {
        //     if (this.authUser.office_detail && this.authUser.office_detail.office_type_id) {
        //         params.office_type_id = this.authUser.office_detail.office_type_id
        //     }
        //     if (this.authUser.office_detail && this.authUser.office_detail.office_id) {
        //         params.office_id = this.authUser.office_detail.office_id
        //     }
        // }
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
            if (service !== undefined) {
                if (service.office_type_id) {
                    params.office_type_id = service.office_type_id
                }
                if (service.office_id) {
                    params.office_id = service.office_id
                }
            }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, employeeDetails + '/' + this.id, params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
        getTeaGardenName (id) {
        const gardenInfo = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
            if (gardenInfo && this.$i18n.locale === 'bn') {
                return gardenInfo.text_bn
            } else if (gardenInfo && this.$i18n.locale === 'en') {
                return gardenInfo.text_en
            }
        },
        getHeadAccountTypeName (id) {
            const obj = this.$store.state.TeaGardenService.commonObj.headAccountType.find(item => item.value === id)
            if (obj) {
                return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
            }
        }
    }
}
</script>
